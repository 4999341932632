import other from './other';
import settings from './settings';
import reminders from './reminders';
import notifications from './notifications';
import analytics from './analytics';

// -----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
  items: [other, reminders, notifications, analytics, settings],
};

export default menuItems;
