import { gql } from '@apollo/client';

export const ME = gql`
  query me {
    me {
      uuid
      firebaseUid
      hasRole
      emailVerified
      isStaff
      isSuperuser
      getName
      email
      clinicCoordinator {
        uuid
        role
        firstName
        lastName
        contentTypeId
        phoneNumber
        photo
        user {
          uuid
          id
        }
      }
      student {
        id
        uuid
        profile {
          avatar
        }
      }
      preceptor {
        id
        uuid
        profile {
          avatar
        }
      }
      lastUsedRole {
        key
        value
      }
      wizardState {
        isWizardCompleted
        lastWizardStep
      }
    }
  }
`;

export const ALL_USER_EMAILS_QUERY = gql`
  query allUserEmails {
    allUserEmails {
      emailAddress
      fullName
    }
  }
`;

export const USER_EMAILS_QUERY = gql`
  query userEmails($first: Int, $skip: Int, $search: String) {
    userEmails(first: $first, skip: $skip, search: $search) {
      uuid
      emailAddress
      fullName
    }
  }
`;

export const STUDENT_EMAILS_QUERY = gql`
  query studentEmails($first: Int, $skip: Int, $search: String) {
    studentEmails(first: $first, skip: $skip, search: $search) {
      uuid
      id
      emailAddress
      fullName
    }
  }
`;

export const UPLOAD_AVATAR_CC = gql`
  mutation uploadCcAvatar($clinicCoordinarotUuid: String!, $file: Upload!) {
    uploadCcAvatar(clinicCoordinarotUuid: $clinicCoordinarotUuid, file: $file) {
      success
      avatarUrl
    }
  }
`;

export const DELETE_AVATAR_CC = gql`
  mutation deleteCcAvatar($clinicCoordinarotUuid: String!) {
    deleteCcAvatar(clinicCoordinarotUuid: $clinicCoordinarotUuid) {
      success
    }
  }
`;
