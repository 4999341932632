import {
  Analytics,
} from '@material-ui/icons';

import paths from 'paths';

export default {
  id: 'analytics-menu',
  type: 'group',
  children: [
    {
      id: 'analytics',
      title: 'Analytics',
      type: 'item',
      url: `${paths.analytics.reverse()}`,
      icon: Analytics,
      breadcrumbs: false,
    },
  ],
};
