import React, { useEffect, useRef, useState } from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Avatar,
  Backdrop,
  Box,
  ButtonBase,
  CardContent,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { FormControlLabel, Switch } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useInView } from 'react-intersection-observer';

// project imports
import { IconBell } from '@tabler/icons';
import useNotificationsCRUD from 'hooks/useNotifications';
import Notification from './Notification';
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';

// assets

// style constant
const useStyles = makeStyles(theme => ({
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 205px)',
    overflowX: 'hidden',
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
    '&[aria-controls="menu-list-grow"],&:hover': {
      background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
      color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light,
    },
    position: 'relative',
    overflow: 'visible',
  },
  cardContent: {
    padding: '0px !important',
  },
  notificationChip: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.warning.dark,
  },
  notificationChipMain: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.warning.dark,
    position: 'absolute',
    right: '-8px',
    bottom: '-8px',
  },
  divider: {
    marginTop: 0,
    marginBottom: 0,
  },
  cardAction: {
    padding: '10px',
    justifyContent: 'center',
  },
  paddingBottom: {
    paddingBottom: '16px',
  },
  box: {
    marginLeft: '16px',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },
  bodyPPacing: {
    padding: '16px 16px 0',
  },
  textBoxSpacing: {
    padding: '0px 16px',
  },
}));

// -----------------------|| NOTIFICATION ||-----------------------//

const NotificationSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [readedUuid, setReadedUuid] = useState(false);
  const [first, setFirst] = useState(10);
  const [isOnlyReaded, setOnlyReaded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const anchorRef = useRef(null);
  const { ref, inView } = useInView();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const {
    allNotifications,
    notifications,
    readNotificationLoading,
    getNotifications,
    handleReaded,
  } = useNotificationsCRUD();

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    getNotifications({
      variables: {
        first,
        skip: '0',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNotifications({
      variables: {
        first,
        skip: '0',
        isReaded: isOnlyReaded,
      },
    }).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyReaded, first]);

  useEffect(() => {
    if (inView) {
      setFirst(f => f + 10);
    }
  }, [inView]);

  useEffect(() => {
    setHasMore(!(allNotifications?.totalCount === allNotifications?.results?.length));
  }, [allNotifications]);

  return (
    <React.Fragment>
      <Box component="span" className={classes.box}>
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <IconBell stroke={1.5} size="1.3rem" />
            {!!allNotifications.totalUnreaded && <Chip size="small" label={allNotifications.totalUnreaded} className={classes.notificationChipMain} />}
          </Avatar>
        </ButtonBase>
      </Box>
      <Backdrop
        open={open}
        onClick={() => {
          if (!readNotificationLoading) {
            setOpen(false);
          }
        }}
        sx={{ zIndex: 1400, position: 'fixed' }}
      />
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1401 }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.bodyPPacing}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                              <Stack direction="row" spacing={2}>
                                <Typography variant="subtitle1">All Notification</Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <PerfectScrollbar className={classes.ScrollHeight}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                              <Stack direction="row" ml={2}>
                                <FormControlLabel
                                  control={(
                                    <Switch
                                      checked={isOnlyReaded}
                                      onChange={ev => { setOnlyReaded(ev.target.checked); setLoading(true); }}
                                    />
                                  )}
                                  label="Only show unread"
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={12} p={0}>
                              <Divider className={classes.divider} />
                            </Grid>
                            <Grid item xs={12}>
                              { isLoading ? (
                                <Box justifyContent="center" width="330px" display="flex">
                                  <CircularProgress />
                                </Box>
                              ) : (
                                <>
                                  {notifications?.map(notification => (
                                    <Notification
                                      title={notification.title}
                                      body={notification.body}
                                      isReaded={notification.isReaded}
                                      createdAt={notification.createdAt}
                                      readNotification={() => {
                                        handleReaded(notification.uuid).then(() => {
                                          window.location.href = notification.actionUrl;
                                        });
                                      }}
                                      readNotificationLoading={readNotificationLoading}
                                      readedUuid={readedUuid === notification.uuid}
                                      setReadedUuid={() => {
                                        setReadedUuid(notification.uuid);
                                      }}
                                    />
                                  ))}
                                  {!notifications?.length && <div ref={ref} style={{ height: '40px', textAlign: 'center' }}> Notification empty </div>}
                                  { hasMore && <div ref={ref} style={{ height: '40px', textAlign: 'center' }}> Loading... </div>}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </PerfectScrollbar>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default NotificationSection;
