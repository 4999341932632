import { useMutation, useLazyQuery } from '@apollo/client';
import { ALL_NOTIFICATIONS } from 'queries/notifications/queries';
import { READ_NOTIFICATION, DELETE_NOTIFICATIONS, UN_READ_NOTIFICATION } from 'queries/notifications/mutations';
import { useState, useEffect } from 'react';

const useNotificationsCRUD = () => {
  const [notifications, setNotifications] = useState([]);

  const [getNotifications, {
    loading,
    called,
    data: { paginatedNotifications: allNotifications = [] } = {},
  }] = useLazyQuery(ALL_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
  });

  const [deleteNotifications, { loading: deleteLoading }] = useMutation(DELETE_NOTIFICATIONS);

  const [readNotification, { loading: readNotificationLoading }] = useMutation(READ_NOTIFICATION);
  const [unReadNotification, { loading: unReadNotificationLoading }] = useMutation(UN_READ_NOTIFICATION);

  const handleReaded = notificationUuid => readNotification({
    variables: {
      notificationUuid,
    },
  });

  const handleUnReaded = notificationUuid => unReadNotification({
    variables: {
      notificationUuid,
    },
  });

  useEffect(() => {
    if (allNotifications?.results && !loading) {
      setNotifications(allNotifications?.results);
    }
  }, [allNotifications, loading]);

  return {
    loading,
    deleteLoading,
    allNotifications,
    called,
    notifications,
    readNotificationLoading,
    unReadNotificationLoading,
    handleReaded,
    getNotifications,
    setNotifications,
    deleteNotifications,
    handleUnReaded,
  };
};

export default useNotificationsCRUD;
