/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Backdrop,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';

// style constant
const useStyles = makeStyles(theme => ({
  navContainer: {
    width: '100%',
    maxWidth: '330px',
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
  listAction: {
    top: '0px',
  },
  actionColor: {
    color: theme.palette.grey[500],
  },

  listItem: {
    padding: 0,
    position: 'relative',
  },
  sendIcon: {
    marginLeft: '8px',
    marginTop: '-3px',
  },
  listDivider: {
    marginTop: 0,
    marginBottom: 0,
  },
  listChipError: {
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
    height: '24px',
    padding: '0 6px',
    marginRight: '5px',
  },
  listChipWarning: {
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
    height: '24px',
    padding: '0 6px',
  },
  listChipSuccess: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
    height: '24px',
    padding: '0 6px',
  },
  listAvatarSuccess: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
    border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
    borderColor: theme.palette.success.main,
  },
  listAvatarPrimary: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
    borderColor: theme.palette.primary.main,
  },
  listContainer: {
    paddingLeft: '56px',
  },
  uploadCard: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
  },
  paddingBottom: {
    paddingBottom: '16px',
  },
  itemAction: {
    cursor: 'pointer',
    position: 'relative',
    padding: '16px',
    '&:hover': {
      background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    },
  },
}));

const Notification = ({
  title,
  body,
  isReaded,
  createdAt,
  readNotification,
  readNotificationLoading,
  readedUuid,
  setReadedUuid,
}) => {
  const classes = useStyles();

  const getTime = dateStr => {
    const date = new Date(dateStr);
    return `${date.toISOString().split('T')[0]} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  return (
    <List
      onClick={() => {
        if (!readNotificationLoading) {
          setReadedUuid();
          readNotification();
        }
      }}
      className={classes.navContainer}
    >
      <div className={classes.itemAction} style={!readNotificationLoading ? { cursor: 'pointer' } : { cursor: 'auto' }}>
        <ListItem alignItems="center" className={classes.listItem}>
          <ListItemAvatar>
            <Avatar src="/broken-image.jpg" />
          </ListItemAvatar>
          <ListItemText primary={<Typography variant="subtitle1">{title}</Typography>} />
          <ListItemSecondaryAction className={classes.listAction}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className={classes.actionColor}
                >
                  {getTime(createdAt)}
                </Typography>
              </Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction="column" className={classes.listContainer}>
          <Grid item xs={12} className={classes.paddingBottom}>
            <Typography variant="subtitle2">
              {body}
            </Typography>
          </Grid>
          { !isReaded && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Chip label="Unread" className={classes.listChipError} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Backdrop
          open={readNotificationLoading && readedUuid}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Divider className={classes.listDivider} />
    </List>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  isReaded: PropTypes.string,
  createdAt: PropTypes.string,
  readNotification: PropTypes.func,
  readNotificationLoading: PropTypes.bool,
  readedUuid: PropTypes.string,
  setReadedUuid: PropTypes.func,
};

export default Notification;
