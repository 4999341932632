import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

// project imports
import { useQuery } from '@apollo/client';
import { ME } from 'queries/account/queries';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import config from '../../config';

// -----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();

  const { data: { me } = {} } = useQuery(ME, {
    fetchPolicy: 'network-only', skip: !isLoggedIn,
  });

  if (isLoggedIn && me?.isStaff === false) {
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      variant: 'alert',
      alertSeverity: 'error',
      message: 'You don\'t have permission to access this resource.',
    });
  }

  if (isLoggedIn && me?.isStaff === true) {
    return <Redirect to={config.defaultPath} />;
  }

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
